<template>
  <div class="w1092">
     <h4 class="pageName">출금</h4>
      <div class="moneyinfoappli m0">
        <div class="inputLeft w430">
          <p class="input">
             <i>코인</i>
             <span><em></em></span>
             <select v-model="selectedCoin">
               <option :value="selectedCoin" >{{selectedCoin.coin}}</option>
             </select>
          </p>
          <p class="input">
             <i>네트워크</i>
             <span>{{selectedCoin.coin}}</span>
             <select v-model="selectedNetwork">
               <option v-for="(item, index) in networkList" :key="index" :value="item">{{item.name}}</option>
             </select>
          </p>
        </div>
        <div class="inputLeft">
          <p class="input">
             <input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.cash.noValidPrice')" v-model="getCurrentChargeMoney" />
             <em>{{selectedCoin.coin}}</em>
          </p>
          <div class="calc">
             <a @click="getCalculate()">계산하기</a>
             <p class="input">
                 <i><img src="@/assets/img/icon_coin.svg" />출금캐시</i>
                 <span>{{thousand(cashCalculate)}}</span>
                 <em></em>
             </p>
          </div>
        </div>
      </div>
      <p class="wallet">
        <input type="text" v-model="wallet.address" placeholder="출금 지갑 주소" class="numb">
        <img src="@/assets/img/icon_copy.svg" />
      </p>

      <div class="coinInfo">
        <ul class="info">
          <li>
             <em>EXpected Arrival</em>
             <span>{{selectedNetwork.minConfirm}} network_confirmations</span>
          </li>
          <li>
             <em>EXpected Unlock</em>
             <span>{{selectedNetwork.unlockConfirm}} network_confirmations</span>
          </li>
          <li>
             <em>최소 출금 액수</em>
             <span>{{ thousand(selectedNetwork.withdrawMin) }}</span>
          </li>
          <li>
             <em>출금 수수료</em>
             <span>{{ thousand(selectedNetwork.withdrawFee) }}</span>
          </li>
        </ul>
      </div>

      <div class="chargeList">
        <p class="appliinput top">출금 예정 금액</p>
        <div class="appliinput two">
          <ul>
             <li><img src="@/assets/img/icon_coin.svg" />{{$t('front.cash.cashHave')}}</li>
             <li><span>{{thousand(userData.cashAmt)}}</span></li>
          </ul>
        </div>
        <div class="appliinput two">
          <ul>
             <li><img src="@/assets/img/icon_cash.svg" />{{$t('front.board.withdrawMoney')}}</li>
             <li class="cash">{{thousand(cashCalculate)}}</li>
          </ul>
        </div>
        <div class="appliinput two">
          <ul>
             <li><img src="@/assets/img/icon_cash.svg" />출금 후 예상금액</li>
             <li><span>{{thousand(Number(userData.cashAmt) - cashCalculate)}}</span></li>
          </ul>
        </div>
      </div>

      <div class="submitBtn">
        <a @click="onSubmit">{{thousand(cashCalculate)}} 캐시 {{$t('front.cash.moneyCheckListL')}}</a>
      </div>

    <div class="board">
      <ul class="boardw first">
        <li class="">{{$t('front.board.applyDay')}}</li>
        <li class="">{{$t('front.board.withdrawMoney')}}</li>
        <li class="">{{$t('front.board.processDay')}}</li>
        <li class="">{{$t('front.board.processState')}}</li>
      </ul>
      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li class="">{{dateFormat(item.regDate)}}</li>
          <li class="">{{thousand(item.cashAmt)}}</li>
          <li class="">{{dateFormat(item.updDate)}}</li>
          <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>
    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'

import { cashDelete, cashOut, gameMoneyToCash } from '@/api/cash'
import store from '@/store'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'

export default {
  name: 'Exchange',
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    getCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.getCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      console.log(n)
      this.getCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      getCurrentChargeMoney: '',
      cashOutPass: '',
      allChecked: false,
      cashList: [],
      cashType: 'out',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      }
    }
  },
  methods: {
    async moneyChange () {
      if (this.coinAmt && this.coinAmt !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmGameMoneyToCash')
        if (confirm) {
          const params = {
            cashAmt: this.coinAmt
          }
          gameMoneyToCash(params).then(response => {
            const result = response.data
            if (result.resultCode === '0') {
              store.dispatch('storeUserCoinAmt')
              store.dispatch('storeUserData')
              // this.userData.coinAmt = data.balance
              this.onCheck('front.cash.pointToCashComplete')
            }
          })
        }
      } else {
        this.onCheck('front.cash.emptyGamePoint')
      }
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.getCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        if (money + value <= this.userData.cashAmt) {
          money += value
        } else {
          this.onCheck('환전 금액이 현재 보유캐시보다 큽니다.')
        }
      } else {
        money = value
      }

      this.getCurrentChargeMoney = thousand(money)
    },
    async onSubmit (device = 'mobile') {
      if (!this.userData.outAmtYn || this.userData.outAmtYn === 'N') {
        this.onCheck('출금 정지된 상태입니다.')
        return false
      }
      if (!this.cashOutPass) {
        this.onCheck('출금 비밀번호를 입력해주세요.')
        return false
      }
      if (this.getCurrentChargeMoney || this.getCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmExchange')

        if (confirm) {
          const params = {
            memId: this.userData.memId,
            cashType: -1, // in,
            cashStatus: 'out',
            cashAmt: this.getCurrentChargeMoney.replace(/,/g, ''),
            cashOutPass: this.cashOutPass
          }

          cashOut(params).then(response => {
            store.dispatch('storeUserData').then(userData => {

            }).then(async responseInfo => {
              const result = response.data
              if (result.resultCode === '0') {
                const confirm = await this.onCheck('front.cash.completeExchange')
                if (confirm) {
                  if (device === 'mobile') {
                    this.replacePageByName('my')
                  } else {
                    location.reload()
                  }
                }
              } else {
                this.onCheck('api.' + result.resultCode)
              }
            })
          }).catch(err => {
            console.error(err)
            this.onCheck('출금 비밀번호를 확인해주세요.')
          })
        }
      } else {
        this.onCheck('front.cash.emptyPrice')
      }
    }
  }
}
</script>
<style scoped>
.moneyinfoappli {margin-bottom: 150px;display: flex;gap: 22px;}
.inputLeft {width: 777px;}
.inputLeft .input, .input {height: 54px;border: solid 1px #53474b;font-size: 20px;display: flex;align-items: center;justify-content: end;gap: 10px;padding: 0 25px;margin-bottom: 35px;}
.input i {margin-right: auto;font-size: 16px;display: flex;gap: 18px;align-items: center;}
.input i img {vertical-align: middle;}
.input .pw {font-size: 16px;text-align: center;margin: 0 auto;}
.inputLeft input {border: 0;background: none;color: #ff9ea8;font-size: 20px;text-align: right;}
.moneybtnwrap {overflow: hidden;margin-bottom: 20px;}
.moneybtnwrap li {float: left;margin-left: 12px;margin-bottom: 14px;}
.moneybtnwrap li:nth-child(4n+1) {margin-left: 0;}
.moneybtnwrap a {display: flex;align-items: center;justify-content: center;font-size: 16px;width: 185px;height: 54px;border-radius: 5px;border: solid 1px #aa3b47;background-color: #1e1b1c;box-sizing: border-box;}
.moneybtnwrap .one a {border: solid 1px #6d6c7c;}
.inputLeft .check {font-size: 16px;display: flex;align-items: center;justify-content: center;gap: 12px;margin: 11px 0 25px;}
.applibtns a {border-radius: 5px;background-color: #9d303a;border: 1px solid #6d6d6d;width: 777px;height: 88px;font-size: 22px;display: flex;align-items: center;justify-content: center;}

.calc {display: flex;gap: 22px;}
.calc a {width: 176px;height: 54px;display: flex;align-items: center;justify-content: center;font-size: 16px;border-radius: 5px;border: solid 1px #6d6d6d;background-color: #9d303a;}
.calc .input {width: 506px;}
.wallet {height: 54px;border: solid 1px #53474b;background-color: #1e1b1c;padding: 0 25px 0 18px;display: flex;align-items: center;margin-bottom: 40px;}
.wallet input {width: 100%;background: none;border: 0;padding: 0;color: #b2a394;font-size: 16px;}
.coin {width: 719px;}
.coin .input span {display: flex;align-items: center;gap: 10px;}
.coin .input em {width: 26px;height: 26px;background: #2a9e7c;border-radius: 30px;display: block;}
.inputRight.coinInfo {width: 458px;background: #1e1b1c;padding: 30px 25px;font-size: 16px;}
.coinInfo ul {overflow: hidden;}
.coinInfo em {color: #716c6e;margin-bottom: 10px;display: block;}
.coinInfo span {color: #b2a394;display: block;}
.coinInfo li:nth-child(1), .coinInfo li:nth-child(2) {margin-bottom: 55px;}
.coinInfo li {width: 50%;float: left;}
.info {display: flex;align-items: center;gap: 22px;margin-bottom: 40px;}
.info li {border: solid 1px #53474b;background-color: #1e1b1c;width: 100%;margin: 0 !important;padding: 14px 18px;}
.cash {color: #ff9ea8;font-size: 22px;}
.input select {font-size: 16px;color: #6d6d6d;background: none;border: 0;}
.chargeList {padding: 28px 30px 30px 30px;border: solid 1px #53474b;}
.inputRight {border: solid 1px #53474b;width: 400px;}
.appliinput {display: flex;align-items: center;justify-content: space-between;margin: 0 25px;border-bottom: 1px solid #33323b;}
.appliinput:last-child {border-bottom: 0;}
.appliinput.top {font-size: 20px;padding: 28px 0 26px;}
.appliinput.two {padding: 23px 0 20px;display: block;font-size: 16px;}
.appliinput.two ul {display: flex;align-items: center;justify-content: space-between;color: #989898;}
.appliinput.two ul:first-child {}
.appliinput.two img {vertical-align: middle;margin-right: 10px;}
.appliinput.two ul span {font-size: 20px;margin-right: 10px;}
.appliinput.one {padding: 23px 0 20px;font-size: 18px;}
.appliinput.one .cash {max-width: 220px;border: 0;background: none;color: #ff9ea8;font-size: 22px;text-align: right;margin-right: 10px;}
.submitBtn {display: flex;align-items: center;justify-content: center;height: 65px;color: #fff;font-size: 20px;border-radius: 5px;border: solid 1px #6d6d6d;background-color: #9d303a;margin-top: 40px;}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
